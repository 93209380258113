import React from 'react';
import styled from 'styled-components';
import SvgInline from '@latitude/svg-inline';
import { Text } from '@latitude/text';
import { Box } from '@latitude/box';
import { Heading3, Heading6 } from '@latitude/heading';
import { BREAKPOINT, COLOR, PADDING } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import { CardGroup, CardGroupItem } from '../CardGroup/CardGroup';

const QuickLinks = props => {
  const title = props.title || "Let's explore";
  const data = props.data || [
    {
      href: '/contact-us/',
      icon: 'chat',
      title: 'Contact us',
      text: 'Everyone has questions. What are yours?'
    },
    {
      href: '/hardship-care/',
      icon: 'bills',
      title: 'Hardship Care',
      text: 'If your life changes, we’re here to help.'
    },
    {
      href: '/credit-cards/#pay-any-way',
      icon: 'icon-pay-any-way',
      title: 'Pay Any Way',
      text: 'Any mobile. Any wearable. Including Apple Pay and Samsung Pay.'
    }
  ];

  return (
    <OuterBox className={props.className}>
      <InnerBox isResponsive>
        <Heading>{title}</Heading>
        <StyledCardGroup>
          {data.map((item, i) => (
            <CardGroupItem key={i} col={4}>
              <ItemLink
                noStyle
                href={item.href}
                trackId={item.trackId}
                trackEventData={{
                  label: item.title,
                  category: 'text-link',
                  location: title
                }}
                trackProductId={item.trackProductId}
              >
                <ItemIcon name={item.icon} />
                <Seperator />
                <Box>
                  <ItemTitle>{item.title}</ItemTitle>
                  <Text>{item.text}</Text>
                </Box>
              </ItemLink>
            </CardGroupItem>
          ))}
        </StyledCardGroup>
      </InnerBox>
    </OuterBox>
  );
};

const OuterBox = styled(Box)`
  background-color: #f8f8f8;
  padding-top: 48px;
  padding-bottom: 48px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
`;

const InnerBox = styled(Box)`
  margin: 0 auto;
`;

const Heading = styled(Heading3)`
  text-align: center;
  color: ${COLOR.BLACK};
  font-size: 24px;
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 32px;
  }
`;

const StyledCardGroup = styled(CardGroup)`
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.SM}) {
    max-width: 80%;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 50%;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 100%;
  }
`;

const ItemLink = styled(Link)`
  display: flex;
  padding: ${PADDING.P24};
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49);
    transition: all 150ms ease-in-out;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    height: 100%;
  }
`;

const ItemIcon = styled(SvgInline)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  margin-right: 13px;
  stroke: #000;

  .icon-line--style {
    stroke: #000;
  }
`;

const Seperator = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    width: 16px;
    height: 2px;
    background-color: ${COLOR.BLUE};
    margin: 13px 0 20px;
  }
`;

const ItemTitle = styled(Heading6)`
  margin-bottom: 0;
  flex-grow: 1;
  color: ${COLOR.GREY75};
  @media (min-width: ${BREAKPOINT.XL}) {
    font-size: 20px;
    margin-bottom: 16px;
  }
`;

export default QuickLinks;
