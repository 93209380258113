// @flow
import React, { useState, useEffect, useContext } from 'react';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { NotificationBanner } from '@latitude/banner';
import { ImportantInformation } from '@latitude/important-information';
import {
  BREAKPOINT,
  BUTTON_STYLE,
  FONT_FAMILY,
  FONT_WEIGHT,
  COLOR
} from '@latitude/core/utils/constants';
import { Hero } from '@latitude/hero';
import { HeroText } from '@latitude/hero/Hero.styles';
import Section from '@latitude/section';
import { FeaturesSlider } from '@latitude/features-slider';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Link } from '@latitude/link';
import { FeaturesAndBenefitsSlider } from '@latitude/features-and-benefits-slider';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';

import Button from '@/components/Button/Button';
import Layout from '@/components/layout';
import QuickLinks from '@/components/QuickLinks/QuickLinks';
import Promo, { PromoSection } from '@/components/Promo/Promo';
import CalculatorLinks from '@/components/CalculatorLinks/CalculatorLinks';

import { featuresSliderIE11Fix } from '../utils/stylesUtils';
import { SOFT_QUOTE_LOANS_URL } from '../utils/softQuoteUtil';

import PageData from '../data/pages/index.json';
import heroImage from '../images/gemfinance-banner.webp';

import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';
import { PageContext } from '../context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../utils/constants';
import { useSetState } from '@/utils/hooks';

const IndexPage = () => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    promoBannerData: [],
    alertData: [],
    featureSliderData: [
      {
        heading: 'Why partner with Gem?',
        featureCards: PageData.whyChooseLatitude
      }
    ]
  });

  useEffect(() => {
    let [featureSliders, heroBanners, promoBanners] = [[], [], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        case CONTENTFUL_COMPONENTS.PROMO_BANNER:
          promoBanners = [...promoBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      promoBannerData:
        promoBanners.length > 0 ? promoBanners : state.promoBannerData,
      featureSliderData:
        featureSliders.length > 0 ? featureSliders : state.featureSliderData
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Layout noMetaKeywords>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer gem-home-page">
        <Helmet>
          <link rel="canonical" href="https://www.gemfinance.co.nz/" />
          <title>Gem by Latitude | New Zealand Loans &amp; Credit Cards</title>
          <meta
            name="description"
            content="Gem by Latitude is your Partner in Money, from personal loans to credit cards. Let's help you find a way. T&amp;Cs &amp; fees apply."
          />
        </Helmet>
        {!selectDataSourceForAlertBanner(contentfulPageData) && (
          <NotificationBanner siteName="gemfinance-co-nz" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Section
            css={`
              background-color: #0555c8;
              padding: 0;

              @media (max-width: ${BREAKPOINT.MD}) {
                && > .container.section__content {
                  padding: 0;
                }
              }
            `}
          >
            <Hero
              css={`
                && {
                  color: #000;
                }

                #hero {
                  padding-left: 0;
                  padding-right: 0;
                }

                h1 {
                  color: #fff;
                  font-size: 45px;
                  font-style: normal;
                  font-weight: 600;
                  margin-bottom: 20px;
                  font-family: Montserrat;
                }

                p {
                  color: #fff;
                  font-size: 19px;
                  font-weight: normal;
                  max-width: 400px;
                }

                svg {
                  min-height: 45px;
                  min-width: 45px;
                }

                .HeroContent {
                  padding: 17px 0px 59px 0px;
                  line-height: normal;
                }

                [class^='Herostyles__HeroImage-'] img {
                  height: 85%;
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  p {
                    font-size: 20px;
                  }

                  h1 {
                    font-size: 35px;
                    margin: 5px 0;
                  }

                  .HeroContent {
                    padding: 10px;
                    background-color: transparent;
                  }

                  .text-center {
                    color: #000 !important;
                  }
                }
              `}
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-end;
                    }
                  `}
                >
                  <img
                    src={heroImage}
                    alt=""
                    css={`
                      height: 250px;
                      width: auto;
                      @media (min-width: ${BREAKPOINT.MD}) {
                        height: 300px;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        height: 382px;
                      }
                    `}
                  />
                </div>
              }
              className="page-banner-lfs--bg"
            >
              {
                <div
                  id="hero"
                  css={`
                && {
                  padding: 0 15px;
                  color: #fff:

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding: 0;
                  }
                }
              `}
                >
                  <h1>
                    For life’s moments <br /> big and small
                  </h1>
                  <HeroText
                    css={`
                      && {
                        color: #fff;
                      }
                    `}
                  >
                    As your Partners in Money, our attitude is to be
                    responsible, fast and always ready to help.
                    <br />
                    So, what are you looking for today?
                  </HeroText>
                  <div className="row">
                    <div className="col-12 col-md-7 text-left">
                      <HomeButton
                        href="/loans/personal-loans/"
                        secondaryInverse
                        trackId="personal-loans-button"
                        trackEventData={{
                          label: 'Personal Loans',
                          location: 'Hero',
                          category: 'button-link'
                        }}
                        trackProductId={['PLNZLF-WEB']}
                        className="button--chevron-right"
                      >
                        Personal Loans
                      </HomeButton>
                      <HomeButton
                        href="/loans/car-loans/"
                        secondaryInverse
                        trackId="car-loans-button"
                        trackEventData={{
                          label: 'Car Loans',
                          location: 'Hero',
                          category: 'button-link'
                        }}
                        trackProductId={['MLNZLF-WEB']}
                        className="button--chevron-right"
                      >
                        Car Loans
                      </HomeButton>

                      <HomeButton
                        href="/credit-cards/gem-visa-card/"
                        secondaryInverse
                        trackId="credit-cards-button"
                        trackEventData={{
                          label: 'Gem Visa',
                          location: 'Hero',
                          category: 'button-link'
                        }}
                        trackProductId={['CCNZGEM']}
                        className="button--chevron-right"
                      >
                        Gem Visa
                      </HomeButton>
                      <HomeButton
                        href="/credit-cards/interest-free-offers/"
                        secondaryInverse
                        trackId="interest-free-offers-button"
                        trackEventData={{
                          label: 'Interest Free Offers',
                          location: 'Hero',
                          category: 'button-link'
                        }}
                        className="button--chevron-right"
                      >
                        Interest Free Offers
                      </HomeButton>
                    </div>
                  </div>
                </div>
              }
            </Hero>
          </Section>
        )}

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            css={featuresSliderIE11Fix}
            heading={state.featureSliderData[0].heading}
            subheading={state.featureSliderData[0].description}
            data={state.featureSliderData[0].featureCards}
            responsiveSettings={PageData.whyChooseLatitudeResponsoveSettings}
          />
        )}
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <PromoSection css="background-color: #F8F8F8;">
            <Promo
              title={
                <>
                  <span
                    css={`
                @media (min-width: ${BREAKPOINT.LG}) {
                  font-size:31px;
                }
              }
            `}
                  >
                    Check your Personal Loan Rate in just 2&nbsp;minutes.
                  </span>
                </>
              }
              // personal-loans-gem-nz.png
              description="<sup>*</sup>Rates tailored to you from 8.99%-29.99% p.a. Credit and lending criteria, and fees apply including a $240 establishment fee."
              frameImage={
                <img src={require('../images/pl-rate-drop-3.jpg')} alt="" />
              }
              verticalAlignContent
              frameAlignment="right"
              frameBorderColor="#B1DBFF"
              ctaButtons={
                <Link
                  href={SOFT_QUOTE_LOANS_URL}
                  button={BUTTON_STYLE.PRIMARY}
                  target="_self"
                  trackId="get-my-rate"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link',
                    location: 'Check your Personal Loan Rate in just 2 minutes.'
                  }}
                  trackProductId={['PLNZLF-WEB', 'MLNZLF-WEB']}
                >
                  Get my rate estimate
                </Link>
              }
            />
          </PromoSection>
        )}
        <HorizontalRule />
        {state?.promoBannerData?.[1] ? (
          <PromoBanner {...state?.promoBannerData[1]} />
        ) : (
          <PromoSection
            css={`
              .section__content {
                max-width: 962px !important;
              }
              .section__content [class^='Promo__PromoContentContainer-'] {
                padding-top: 0;
              }
            `}
          >
            <Promo
              title={
                <>
                  <div
                    css={`
                   font-size: 32px;
                   color:#000;
                    @media (max-width: ${BREAKPOINT.LG}) {
                      text-align:center;
                    }
                  }
                `}
                  >
                    Let&apos;s shop with <br />
                    Gem Visa.
                  </div>
                </>
              }
              description="Swipe some awesome deals with your Gem Visa and get cash back credit."
              footerText="<br /><sup>*</sup>&nbsp;Gem Visa T&amp;Cs and fees apply including a $55 establishment fee and $65 annual fee (charged $32.50 half-yearly). Interest rate currently 29.49% p.a. applies after interest free term ends. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Further information on rates and fees can be found at gemfinance.co.nz. Available on participating Gem Visa credit cards only. New customers must apply and be approved for a Gem Visa credit card."
              frameImage={
                <img
                  name="cardless-payment"
                  alt="cardless-payment"
                  src={require('../components/Header/images/bg-banner-cc-new.png')}
                />
              }
              frameAlignment="left"
              frameBackgroundColor="#CDE9FE"
              frameBorderColor="#b1dbff"
              verticalAlignContent
              ctaButtons={
                <Link
                  href="https://www.offers-exchange.com/gemvisa"
                  button={BUTTON_STYLE.SECONDARY}
                  trackId="gemvisa-banner__find-out-more"
                  trackEventData={{
                    category: 'button-link',
                    action: 'internal-link',
                    location: "Let's shop with Gem Visa."
                  }}
                  trackProductId={['CCNZGEM']}
                  css={`
                    && {
                      @media (max-width: ${BREAKPOINT.LG}) {
                        margin-left: auto;
                        margin-right: auto;
                      }
                    }
                    background: #0046aa !important;
                    border-color: #0046aa !important;
                    max-width: 156px;
                  `}
                >
                  Find out more
                </Link>
              }
            />
          </PromoSection>
        )}

        <CalculatorLinks />

        <WhatsImportantSlider
          css="background-color:#f8f8f8;"
          data={PageData.categories}
          className="whats-important"
          heading="What’s important to you?"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />

        <QuickLinks
          heading="Let's explore"
          data={[
            {
              href: '/contact-us/',
              title: 'Contact us',
              text: 'Still have questions or need some help? Give us a call!',
              icon: 'chat',
              trackId: 'quicklinks-contact-us'
            },
            {
              href: '/about-us/',
              title: 'About us',
              text: 'Find out more about Gem and how we can help you.',
              icon: 'about-us',
              trackId: 'quicklinks-about-us'
            },
            {
              href: '/loans/budget-planner/',
              title: 'Budget planner',
              text:
                'Gain more control over your finances \u2014 and your life!',
              icon: 'calculator',
              trackId: 'quicklinks-budget-planner',
              trackProductId: ['PLNZLF-WEB', 'MLNZLF-WEB']
            },
            {
              href: '/hardship-care/',
              title: 'Hardship Care',
              text:
                "If you're struggling with your repayments, we're here to help.",
              icon: 'bills',
              trackId: 'quicklinks-hardship-care'
            }
          ]}
        />

        <ImportantInformation
          data={require('../data/json/disclaimer/default.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
        />
      </main>
    </Layout>
  );
};

const HomeButton = styled(Button)`
  && {
    color: #000 !important;
    font-size: 14px;
    height: 38px;
    padding-left: 16px;
    margin-bottom: 8px;
    &:hover {
      box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
      transition: all 150ms ease-in-out;
    }
  }
`;

const WhatsImportantSlider = styled(FeaturesAndBenefitsSlider)`
  && {
    padding-top: 48px;
    padding-bottom: 48px;

    @media (min-width: ${BREAKPOINT.LG}) {
      padding-top: 72px;
      padding-bottom: 72px !important;
    }

    @media (max-width: ${BREAKPOINT.LG}) {
      .container.section__content {
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
    }

    .loan-used-for-categories__title,
    .card-icon__title {
      font-family: ${FONT_FAMILY.TITLE};
      color: ${COLOR.BLACK};
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      margin-top: 0 !important;
    }

    .loan-used-for-categories__title {
      font-size: 24px;
      line-height: 32px;
      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 32px;
      }
    }

    .lfs-slide {
      background-color: #fff;
      padding-top: 0 !important;
      padding-bottom: 38px !important;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    }

    .card-icon {
      padding-top: 0;
      padding-bottom: 0;
    }

    .card-icon__image {
      position: relative;
      z-index: 0;
      margin-left: -16px;
      width: calc(100% + 32px);
      max-height: 192px;
      overflow: hidden;
      img {
        border-radius: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .card-icon__title {
      @media (max-width: ${BREAKPOINT.LG}) {
        line-height: 24px;
      }
    }

    .card-icon__title,
    .card-icon__content {
      padding: 0;
    }

    .card-icon__title {
      font-size: 18px;
      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 20px;
      }
    }
  }
`;

export default IndexPage;
